<div>
    <app-content-container [content_template]="page_content" [is_loading]="is_loading" [loading_text]="loading_text"
                           [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div>
        <div *ngIf="is_loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <main class="tiles padding">
            <a routerLink="/bot/events" class="link-clear" *ngIf="is_logged_in">
                <app-icon-tile title="Bot-Events" icon="fas fa-calendar"
                               [customClass]="'bg-card bg-bot-events'"></app-icon-tile>
            </a>

            <a routerLink="/bot/resources" class="link-clear">
                <app-icon-tile title="Resources" icon="fab fa-discord"
                               [customClass]="'bg-card bg-bot-resources'"></app-icon-tile>
            </a>

            <a routerLink="/bot/cargo-sell-planer" class="link-clear">
                <app-icon-tile title="cargo sell planer" icon="fab fa-discord"
                               [customClass]="'bg-card bg-cargo-sell-planer'"></app-icon-tile>
            </a>

            <a routerLink="/bot/about" class="link-clear">
                <app-icon-tile title="about bot" icon="fab fa-discord"
                               [customClass]="'bg-card bg-about-bot'"></app-icon-tile>
            </a>
        </main>
    </div>
</ng-template>

