import {ChangeDetectorRef, Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    transition,
    trigger,
    query,
    style,
    animate,
    sequence,
    stagger
} from '@angular/animations';
import $ from 'jquery';
import {AuthService} from './services/auth.service';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {OverlayContainer} from '@angular/cdk/overlay';
import {ThemeService} from './services/theme.service';
import {MatSelectChange} from '@angular/material/select';
import {Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {StoreService} from './services/store.service';
import {Location} from '@angular/common';
import {Store} from './class/store';
import {BreadcrumbService} from './services/breadcrumb.service';
import {NotificationService} from './services/notification.service';
import {PrimeNGConfig} from 'primeng/api';
import {MenuService} from './services/menu.service';
import {CookieService} from './services/cookie.service';

export const ROUTE_ANIMATIONS_ELEMENTS = 'route-animations-elements';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('.1s ease-out', style({ opacity: '1' })),
            ]),
        ]),
        trigger('rowsAnimation', [
            transition('void => *', [
                style({ height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none' }),
                sequence([
                    animate('.1s ease', style({ height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none'  })),
                    animate('.1s ease', style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
                ])
            ])
        ]),
        trigger('fadeAnimation', [
            transition('* => *', [
                query(':enter > *', style({opacity: 0}), {
                    optional: true
                }),
                query(':enter .' + ROUTE_ANIMATIONS_ELEMENTS, style({opacity: 0}), {
                    optional: true
                }),
            ])
        ]),
    ] // register the animations

})
export class AppComponent implements OnInit, OnDestroy {

    private subs: Array<Subscription> = [];
    public pinned_menu = true;
    public height: number;
    public is_open: boolean;
    public login_state: number;
    public is_mobile: boolean;
    public is_small_device: boolean;
    public show_search: boolean;

    public current_route: string;

    public user: any;
    public show_home_message_list = false;

    public notification_options = {
        position: ['bottom', 'right'],
        timeOut: 6000,
        showProgressBar: true,
        clickToClose: true,
        lastOnBottom: false,
        preventLastDuplicates: 'visible',
        pauseOnHover: true,
        maxLength: 60,
        maxStack: 5
    };

    public themes = [
        {value: 'default-theme', name: 'Default'},
        {value: 'black-theme', name: 'Black'},
        {value: 'dark-theme', name: 'Dark'},
        {value: 'red-theme', name: 'Red'},
        {value: 'light-theme', name: 'Light'},
        {value: 'nature-theme', name: 'Nature'}
    ];

    public selected_theme = '';
    public search_context: string;
    public show_back = false;
    public has_tabs = false;
    public page_title = '';
    public report_doc_link: string;

    public loading = false;
    public environment = 'prod';

    public myScUserStore: Store;

    public showConsent = true;

    private mis_Reports: Store;
    private visited_routes = [];

    @HostBinding('class') componentCssClass;
    @ViewChild('sidenav', {static: false}) private _sidenav;
    get sidenav() {
        if (this._sidenav) {
            this.menuService.setSidenav(this._sidenav);
        }
        return this._sidenav;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setMenuDimension();
        this.setScrollHeight();
    }


    constructor(private authService: AuthService,
                private router: Router,
                private translate: TranslateService,
                private overlayContainer: OverlayContainer,
                private themeService: ThemeService,
                private notificationService: NotificationService,
                private cdRef: ChangeDetectorRef,
                private active_route: ActivatedRoute,
                private storeService: StoreService,
                private _location: Location,
                private breadcrumbService: BreadcrumbService,
                private config: PrimeNGConfig,
                private menuService: MenuService,
                private cookieService: CookieService) {

        const lang = localStorage.getItem('selected_lang');
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('de');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        if (lang) {
            this.translate.use(lang);
            this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
        } else {
            // must be set here because default is only a fallback and will not affect current lang
            this.translate.use('de');
            this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
        }

        this.subs.push(
            this.cookieService.open_consent_changed
                .subscribe((isOpen: boolean) => {
                        if (!isOpen) {
                            this.showConsent = !localStorage.getItem('consent_sc_wiki');
                        } else {
                            this.showConsent = true;
                        }
                    }
                )
        );

        this.subs.push(this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.active_route.snapshot),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
            )
            .subscribe((route: ActivatedRouteSnapshot) => {
                // set search context to active route path
                this.search_context = route.routeConfig.path;

                switch (route.routeConfig.data['breadcrumb']) {
                    default:
                        this.page_title = route.routeConfig.data['breadcrumb'];
                        break;
                }

                this.show_back = route.routeConfig.data['show_back'];
                this.has_tabs = route.routeConfig.data['has_tabs'];

                if (route.routeConfig.data['doc_url']) {
                    this.report_doc_link = route.routeConfig.data['doc_url'];
                } else {
                    this.report_doc_link = null;
                }

                if (route.data['show_search']) {
                    this.show_search = true;
                } else {
                    this.show_search = false;
                }

                if (!this.router.url.endsWith('/add')) {
                    if (!(this.visited_routes.length > 0 && this.visited_routes[this.visited_routes.length - 1] !== this.current_route)) {
                        this.visited_routes.push(this.router.url);
                    }
                }

                this.current_route = this.router.url;

                if (this.visited_routes.length === 1 && this.show_back
                    && this.visited_routes[(this.visited_routes.length - 1)] === this.current_route) {
                    this.show_back = false;
                }

                this.checkScUserName(this.router.url);

                switch (this.router.url) {
                    default:
                        this.showConsent = !localStorage.getItem('consent_sc_wiki');
                        break;
                    case '/privacy-police':
                    case '/impressum':
                        this.showConsent = false;
                        break;
                }
            })
        );

        this.router.events.subscribe((event: any) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    this.cdRef.detectChanges();
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    setTimeout(() => {
                        this.loading = false;
                        this.cdRef.detectChanges();
                    }, 300);
                    break;
                }
                default: {
                    break;
                }
            }
        });

        // this.authService.getUserAuthStoreObservable().subscribe((auth_records: any) => {
        //     if (auth_records && auth_records.rows && auth_records.rows.length > 0) {
        //         // set user infos for view
        //         console.log('### set user infos for view');
        //         this.user = auth_records.rows[0];
        //     }
        // });
    }

    ngOnInit(): void {

        this.mis_Reports = this.storeService.getStore('mis_Reports');

        this.subs.push(this.breadcrumbService.breadcrumb_changed.subscribe((breadcrumb: string) => {
            if (breadcrumb && breadcrumb !== '') {
                this.page_title = breadcrumb;
                this.cdRef.detectChanges();
            }
        }));

        this.subs.push(this.authService.login_state_changed.subscribe((state: number) => {
            this.login_state = state;
            switch (state) {
                case 0:
                    // we logged out or are logged out
                    // this.router.navigateByUrl('/checklogin');
                    this.is_open = false;
                    break;
                case 1:
                    // we are on login in (check auth on login)
                    this.is_open = false;
                    break;
                case 2:
                    // we are logged in
                    this.user = this.authService.getUser();
                    // if (this.authService.getGroup('right_admin')) {
                    //     this.show_home_message_list = true;
                    // }
                    this.myScUserStore = this.storeService.getStore('my_sc_user');
                    this.myScUserStore.loadStore('?no_limit=1').then((records) => {
                        this.checkScUserName('main');
                    });
                    // load all table stores
                    /*
                    for (let i = 0; i < this.table_stores.length; i++) {
                        this.storeService.getStore(this.table_stores[i]).loadStore();
                    }
                     */
                    break;
            }
            this.cdRef.detectChanges();
        }));

        this.subs.push(this.authService.user_loaded_changed.subscribe((loaded: boolean) => {
            if (loaded) {
                this.user = this.authService.getUser();
            }
            this.cdRef.detectChanges();
        }));

        this.subs.push(this.themeService.theme_changed.subscribe((theme: string) => {
            this.onSetTheme(theme);
        }));

        this.setMenuDimension();
        this.setScrollHeight();
        this.pinned_menu = this.getPinnedMenu();
        this.cdRef.detectChanges();
    }

    private checkScUserName(route: string) {
        if (route !== '/profile') {
            if (this.login_state === 2 && this.myScUserStore.isLoaded()) {
                const records = this.myScUserStore.getRecords();
                if (records.length === 0) {
                    this.notificationService.warn('Missing SC username',  'Please set user SC username first', 10000);
                    this.router.navigateByUrl('/profile');
                } else if (records[0].data.sc_username === ''
                    || records[0].data.sc_username === null
                    || records[0].data.sc_username === undefined) {
                    this.notificationService.warn('Missing SC username',  'Please set user SC username first', 10000);
                    this.router.navigateByUrl('/profile');
                }
            }
        }
    }

    private setMenuDimension() {
        this.height = $('.side-nav-content').height() - 30;
        this.is_small_device = this.isSmallDevice();
    }

    public togglePinnedMenu() {
        this.pinned_menu = !this.pinned_menu;
        this.savePinnedMenu();
        // Trick to trigger animation
        this.toggleSidenav();
        this.toggleSidenav();
        this.cdRef.detectChanges();
    }

    private savePinnedMenu() {
        if (this.pinned_menu) {
            localStorage.setItem('pinned_menu', '1');
        } else {
            localStorage.setItem('pinned_menu', '0');
        }
    }

    private getPinnedMenu() {
        const pinned_menu = localStorage.getItem('pinned_menu');
        if (!pinned_menu) {
            return true;
        }
        if (pinned_menu && pinned_menu === '1') {
            // pinned on 1
            return true;
        } else if (pinned_menu === '0') {
            // (not set)
            // do we support touch
            this.is_mobile = this.isMobile();
            return this.is_mobile;
        }
        // else not pinned
        return false;
    }

    /**
     * detect a real mobile device
     * @author René Pellenz
     */
    private isMobile(): boolean {
        const a = navigator.userAgent || navigator.vendor || window['opera'];
        // tslint:disable-next-line:max-line-length
        return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4));
    }

    /**
     * detect a small device (could also be a mobile device)
     * @author René Pellenz
     */
    private isSmallDevice(): boolean {
        if (document.documentElement.clientWidth < 800) {
            return true;
        }
        return false;
    }

    public openedChanged(event: boolean) {
        this.is_open = event;
        this.cdRef.detectChanges();
    }

    public toggleSidenav() {
        this.is_open = !this.is_open;
        this.cdRef.detectChanges();
        if (this.login_state === 2) {
            this._sidenav.toggle();
        }
    }

    public logout() {
        this.sidenav.close();
        this.notificationService.success('Logout',
            'Logout erfolgreich.', {timeOut: 5000});
        this.authService.logout();
        this.is_open = false;
        this.cdRef.detectChanges();
    }

    private onSetTheme(theme) {
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.componentCssClass = theme;
        this.selected_theme = theme;
    }

    public themeSelected(event: MatSelectChange) {
        this.themeService.setTheme(event.value);
    }

    public SearchMenu(value: String) {
        if (!this.is_open) {
            this.toggleSidenav();
        }
    }

    public openUrl(url: string) {
        this.router.navigateByUrl(url);
    }

    goBack() {
        let last_route = this.visited_routes[this.visited_routes.length - 1];
        /*
        if (last_route.endsWith('/add')) {
            // do not go back to add pages
            this.visited_routes.pop();
            last_route = this.visited_routes[this.visited_routes.length - 2]._routerState.url;
        }
         */

        if (this.current_route === last_route) {
            // do not load to same route
            this.visited_routes.pop();
            last_route = this.visited_routes[this.visited_routes.length - 1];
            this.router.navigateByUrl(last_route);
        } else if (last_route.indexOf(this.current_route) === 0 && last_route.length > this.current_route.length) {
            // do not go back to child of url if we are in upper level of same url (experimental)
            this.visited_routes.pop();
            this.goBack();
        } else {
            this.visited_routes.pop();
            this.router.navigateByUrl(last_route);
        }
    }

    private setScrollHeight() {
        const tob_bar_height =  $('.top-bar').height();
        const height = window.innerHeight - tob_bar_height;
        // $('.scroll-content').height(window.innerHeight - tob_bar_height);
    }

    public doLogin() {
        this.authService.authWithDiscord();
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    openDoc() {
        window.open(this.report_doc_link, '_blank');
    }

    public hideSideBar() {
        this.menuService.getSidenav().close().then(() => {});
    }
}
