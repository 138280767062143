import {AfterViewInit, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ChartConfiguration, ChartDataset, ChartEvent, ChartType, TooltipItem} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const day = today.getDate();


@Component({
    selector: 'app-auec-info',
    templateUrl: './auec-info.component.html',
    styleUrls: ['./auec-info.component.css'],
})
export class AuecInfoComponent implements AfterViewInit {

    private start_date = new Date(2023, 5, 1);
    private end_date = new Date(year, month, day);

    private selectedDateRange: { start: Date; end: Date } = {
        start:  new Date(2023, 5, 1),
        end:  new Date(year, month, day)
    };

    range = new FormGroup({
        start: new FormControl(this.start_date),
        end: new FormControl(this.end_date),
    });

    public currencies: any[] | undefined;

    private newLabel ? = 'New label';
    public lineChartType: ChartType = 'line';
    @ViewChild(BaseChartDirective) public chart?: BaseChartDirective;

    public lineChartData: ChartConfiguration['data'] = {
        datasets: [
        ],
        labels: [],
    };

    public data_lookup = [];

    // @ts-ignore
    public lineChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        elements: {
            line: {
                tension: 0.5,
                spanGaps: true
            },
        },
        scales: {
            // We use this empty structure as a placeholder for dynamic theming.
            y: {
                position: 'left',
                ticks: {
                    color: '#ffc107'
                },
                grid: {
                    color: '#ffc107',
                    lineWidth: 0.2
                }
            },
            x: {
                position: 'bottom',
                ticks: {
                    color: '#ffc107'
                }
            },
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: 'white'
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                position: 'nearest',
                callbacks: {
                    afterBody: (data) => {
                        const entry = this.data_lookup[data[0].dataIndex];
                        return [entry.source_type, entry.note];
                    }
                }
            },
        },
    };

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private router: Router,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog) {
    }

    ngAfterViewInit(): void {
        this.loadChartData();


        this.range.valueChanges
            .pipe(debounceTime(300))
            .subscribe((value: {start: Date, end: Date}) => {
                this.selectedDateRange = value;
                if (this.selectedDateRange.start && this.selectedDateRange.end) {
                    this.loadChartData();
                }
            });
    }

    // events
    public chartClicked({
                            event,
                            active,
                        }: {
        event?: ChartEvent;
        active?: object[];
    }): void {
        console.log(event, active);
    }

    public chartHovered({
                            event,
                            active,
                        }: {
        event?: ChartEvent;
        active?: object[];
    }): void {
        // console.log(event, active);
    }

    private loadChartData() {
        const currencyStore = this.storeService.createNoneGlobalStore('sc_commodity_inventory',
            'sc-commodity-inventory/type/currency', 'commodity');

        this.lineChartData.datasets = [];
        this.lineChartData.labels = [];

        const filter = [];
        filter.push('filter[insert_ts][1][mode]=dateAfter');
        filter.push('filter[insert_ts][1][operator]=and');
        filter.push('filter[insert_ts][1][value]=' + this.formatDate(this.selectedDateRange.start));
        filter.push('filter[insert_ts][2][mode]=dateBefore');
        filter.push('filter[insert_ts][2][operator]=and');
        filter.push('filter[insert_ts][2][value]=' + this.formatDate(this.selectedDateRange.end));
        filter.push('sort[0][insert_ts]=1');
        filter.push('first=0');
        filter.push('no_limit=1');

        currencyStore.loadStore('?no_limit=1').then((records: any[]) => {
            this.currencies = records;
            if (records && records.length > 0) {
                for (let i = 0; i < records.length; i++) {
                    const curStore = this.storeService.createNoneGlobalStore('sc_commodity_inventory_log',
                        'sc-commodity-inventory/type/currency/'  + records[i].data.commodity + '/log', 'log_id');
                    curStore.loadStore('?' + filter.join('&')).then((entries: any[]) => {
                        // console.log('entries', entries);
                        // combined
                        const in_data = [];
                        const out_data = [];
                        const data = [];
                        let sum = 0;
                        for (let v = 0; v < entries.length; v++) {
                            const value = entries[v].data.value;
                            if (entries[v].data.action === 'in') {
                                sum = sum + value;
                                in_data.push(value);
                                out_data.push(null);
                            } else {
                                sum = sum - value;
                                in_data.push(null);
                                out_data.push(value * -1);
                            }
                            data.push(sum);
                            this.lineChartData.labels.push(entries[v].data.insert_ts);

                            this.data_lookup.push({
                                source_type: entries[v].data.source_type,
                                note: entries[v].data.note
                            });
                        }
                        this.lineChartData.datasets.push(
                            {
                                data: data,
                                label: ' ' + records[i].data.c_name,
                                backgroundColor: 'rgba(148,159,177,0.4)',
                                borderColor: 'rgba(148,159,177,1)',
                                pointBackgroundColor: 'rgba(148,159,177,1)',
                                pointBorderColor: '#fff',
                                pointHoverBackgroundColor: '#fff',
                                pointHoverBorderColor: 'rgba(148,159,177,0.8)',
                                fill: 'none',
                                pointHitRadius: 10
                            },
                        );

                        // in
                        this.lineChartData.datasets.push(
                            {
                                data: in_data,
                                label: ' ' + records[i].data.c_name + ' (in)',
                                backgroundColor: 'rgba(0, 255, 0, 0.4)',
                                borderColor: 'rgba(0, 255, 0, 1)',
                                type: 'bar',
                                barThickness: 20,
                            },
                        );

                        // out
                        this.lineChartData.datasets.push(
                            {
                                data: out_data,
                                label: ' ' + records[i].data.c_name + ' (out)',
                                backgroundColor: 'rgba(255, 0, 0, 0.4)',
                                borderColor: 'rgba(255, 0, 0, 1)',
                                type: 'bar',
                                barThickness: 20
                            },
                        );
                    });
                }
            }
        });
    }

    private formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
}
