import {FbFormObject} from '../class/fb_form_object';

export class ScContractCommodityForm extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'commodity', label: 'commodity', visible: true, noclick: false, disable_ripple: true},
            {id: 'min_required_value', label: 'amount', visible: true, noclick: false, disable_ripple: true},
            // {id: 'max_accepted_value', label: 'max_accepted_value', visible: true, noclick: false, disable_ripple: true}
            {id: 'cat_id', label: 'Category', visible: true, noclick: false, disable_ripple: true},
            {id: 'set_id', label: 'Set', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Commodity',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'commodity',
                        options: this.select_boxes['commodity_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'number',
                        name: 'min_required_value',
                        rules: [
                            {rule: 'required'}
                        ],
                        on_change: (event: any) => {
                            event.model.max_accepted_value = event.model.min_required_value;
                        },
                    },
                    // {
                    //     type: 'number',
                    //     name: 'max_accepted_value',
                    //     rules: [
                    //         {rule: 'required'}
                    //     ]
                    // }
                    {
                        type: 'autocomplete',
                        name: 'cat_id',
                        options: this.select_boxes['cat_select'],
                        disabled: true
                    },
                    {
                        type: 'autocomplete',
                        name: 'set_id',
                        options: this.select_boxes['set_select'],
                        disabled: true
                    },
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_contract_commodities'] = this.storeService.createNoneGlobalStore('sc_contract_commodities',
                'sc-contracts/' + this.custom_obj.c_id + '/commodities', 'cc_id');
            this.stores['sc_available_commodities'] = this.storeService.getStore('sc_available_commodities');
            this.stores['commodity_sets'] = this.storeService.getStore('commodity_sets');
            this.stores['commodity_categories'] = this.storeService.getStore('commodity_categories');

            this.main_store = this.stores['sc_contract_commodities'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');

            const stores = [];
            stores.push(this.stores['sc_contract_commodities'].loadStore(this.filter));
            stores.push(this.stores['sc_available_commodities'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['commodity_sets'].loadStore('?' + selectFilter.join('&')));
            stores.push(this.stores['commodity_categories'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                c_id: this.custom_obj.c_id,
                commodity: null,
                min_required_value: null,
                max_accepted_value: null,
            }
        };
    }

    /**
     * custom reload
     */
    public setSelectboxes() {
        this.parseCommoditySelectBox();
        this.parseSetSelectBox();
        this.parseCategorySelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseCommoditySelectBox() {
        const options = [];
        const records = this.stores['sc_available_commodities'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.c_id),
                name: records[i].data.c_name + ' (' + records[i].data.type + ')'
            });
        }
        this.select_boxes['commodity_select'] = options;
        return this.select_boxes['commodity_select'];
    }

    public parseSetSelectBox() {
        const options = [];
        const records = this.stores['commodity_sets'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.set_id),
                name: records[i].data.set_name
            });
        }
        this.select_boxes['set_select'] = options;
        return this.select_boxes['set_select'];
    }

    public parseCategorySelectBox() {
        const options = [];
        const records = this.stores['commodity_categories'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.cat_id),
                name: records[i].data.cat_name
            });
        }
        this.select_boxes['cat_select'] = options;
        return this.select_boxes['cat_select'];
    }

}
