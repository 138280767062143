import {FbFormObject} from '../class/fb_form_object';

export class ScbMembersPointsSalvage extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'user_id', label: 'User', visible: true, noclick: false, disable_ripple: true},
            {id: 'event_id', label: 'Event', visible: true, noclick: false, disable_ripple: true},
            {id: 'duration100', label: 'Gesamt Teilnahme', visible: true, noclick: false, disable_ripple: true},
            {id: 'on_time', label: 'Vor Ort & Pünktlich', visible: true, noclick: false, disable_ripple: true},
            {id: 'captain', label: 'Kapitän', visible: true, noclick: false, disable_ripple: true},
            {id: 'laser', label: 'Laser', visible: true, noclick: false, disable_ripple: true},
            {id: 'radio_discipline', label: 'Funkdisziplin', visible: true, noclick: false, disable_ripple: true},
            {id: 'full_service', label: 'Tankservice', visible: true, noclick: false, disable_ripple: true},
            {id: 'radio_punishment', label: 'Funkstrafe', visible: true, noclick: false, disable_ripple: true},
            {id: 'did_not_show_up', label: 'Nicht erschienen', visible: true, noclick: false, disable_ripple: true},
            {id: 'ship_destroyed', label: 'Schiff zerstört', visible: true, noclick: false, disable_ripple: true},
            {id: 'orders_delivered', label: 'Aufträge abgeliefert', visible: true, noclick: false, disable_ripple: true},
            {id: 'fast_role', label: 'Schnelllader', visible: true, noclick: false, disable_ripple: true},
            {id: 'valuable_item', label: 'Wertvolles Wrack', visible: true, noclick: false, disable_ripple: true},
            {id: 'optional', label: 'optionale Punkte', visible: true, noclick: false, disable_ripple: true},
            {id: 'comment', label: 'Kommentar', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Salvage Points',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'user_id',
                        options: this.select_boxes['sc_user_select']
                    },
                    {
                        type: 'text',
                        name: 'event_id'
                    },
                    {
                        type: 'select',
                        name: 'duration100',
                        options: [
                            {
                                value: '3',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'on_time',
                        options: [
                            {
                                value: '3',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'captain',
                        options: [
                            {
                                value: '2',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'laser',
                        options: [
                            {
                                value: '1',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'radio_discipline',
                        options: [
                            {
                                value: '1',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'full_service',
                        options: [
                            {
                                value: '5',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'radio_punishment',
                        options: [
                            {
                                value: '-5',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'did_not_show_up',
                        options: [
                            {
                                value: '-5',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'ship_destroyed',
                        options: [
                            {
                                value: '-2',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'orders_delivered',
                        options: [
                            {
                                value: '3',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'fast_role',
                        options: [
                            {
                                value: '2',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'valuable_item',
                        options: [
                            {
                                value: '3',
                                name: 'Ja'
                            },
                            {
                                value: '0',
                                name: 'Nein'
                            }
                        ]
                    },
                    {
                        type: 'number',
                        name: 'optional'
                    },
                    {
                        type: 'text',
                        name: 'comment'
                    }
                ]
            }
        ];

        super.setEditFields();
    }

    /**
     * override / load all stores we need
     */
    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_member_points'] = this.storeService.createNoneGlobalStore('members-points-details-salvage',
                'games/starcitizen/points/salvage', 'id');
            this.stores['sc_user_ifa'] = this.storeService.getStore('sc_user_ifa');
            this.main_store = this.stores['sc_member_points'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');

            const stores = [];
            stores.push(this.stores['sc_member_points'].loadStore(this.filter));
            if (!this.stores['sc_user_ifa'].isLoaded()) {
                stores.push(this.stores['sc_user_ifa'].loadStore('?' + selectFilter.join('&')));
            }

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        const dt = new Date();
        return {
            data: {
                user_id: 0,
                points: 0,
                event_id: 0,
                duration100: 0,
                on_time: 0,
                captain: 0,
                laser: 0,
                radio_discipline: 0,
                full_service: 0,
                radio_punishment: 0,
                did_not_show_up: 0,
                ship_destroyed: 0,
                orders_delivered: 0,
                fast_role: 0,
                valuable_item: 0,
                optional: 0,
                comment: ''
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseScUserSelectbox();
        // call super
        super.setSelectboxes();
    }
    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['sc_user_ifa'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.username
            });
        }
        this.select_boxes['sc_user_select'] = options;
        return this.select_boxes['sc_user_select'];
    }
}
