import {FbFormObject} from '../class/fb_form_object';

export class ScOreOverview extends FbFormObject {

    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'com_id', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'price', label: 'Price', visible: true, noclick: false, disable_ripple: true},
            {id: 'tier', label: 'Tier', visible: true, noclick: false, disable_ripple: true},
            {id: 'sell_info', label: 'Sell (top 3)', visible: true, noclick: false, disable_ripple: true},
            {id: 'last_update', label: 'last update', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Ore',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'com_id',
                        options: this.select_boxes['commodities_select'],
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'price',
                        disabled: true
                    },
                    {
                        type: 'number',
                        name: 'tier',
                        disabled: true
                    },
                    {
                        type: 'text',
                        name: 'sell_info',
                        disabled: true
                    },
                    {
                        type: 'datetime',
                        name: 'last_update',
                        disabled: true
                    }
                ]
            }
        ];

        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.stores['sc_ore'] = this.storeService.createNoneGlobalStore('sc_ore',
                'games/starcitizen/version/' + this.custom_obj.version_id + '/commodities-with-tier-only/ore', 'com_id');

            this.stores['sc_ore_full'] = this.storeService.createNoneGlobalStore('sc_ore_full',
                'games/starcitizen/version/' + this.custom_obj.version_id + '/commodities-with-tier-only/ore', 'com_id');

            this.main_store = this.stores['sc_ore'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');


            const stores = [];
            if (!this.stores['sc_ore'].isLoaded()) {
                stores.push(this.stores['sc_ore'].loadStore(this.filter));
            }
            if (!this.stores['sc_ore_full'].isLoaded()) {
                stores.push(this.stores['sc_ore_full'].loadStore('?' + selectFilter.join('&')));
            }

            Promise.all(stores).then((result) => {
                // all stores loaded
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                com_id: '',
                price: 0,
                tier: 0
            }
        };
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseCommoditySelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseCommoditySelectBox() {
        const options = [];
        const records = this.stores['sc_ore_full'].getRecords();
        for (let i = 0; i < records.length; i++) {
            const rec = records[i];
            options.push({
                value: rec.data.com_id,
                name: rec.data.name
            });
        }
        this.select_boxes['commodities_select'] = options;
        return this.select_boxes['commodities_select'];
    }
}

