<h1 mat-dialog-title>Select Cargo</h1>
<div mat-dialog-content>
    @if (data && data.cargo && data.cargo.length > 0) {
        <mat-form-field style="width: 100%;">
            <mat-label>Search Cargo</mat-label>
            <input type="text" matInput [formControl]="cargoFilter" [matAutocomplete]="auto">

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectCargo($event)">
                <mat-option *ngFor="let cargo of filteredCargo" [value]="cargo.name">
                    {{ cargo.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    }
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onCancel()">Cancel</button>
</div>
