import {Component, Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreService} from '../../../services/store.service';


export interface CargoDialogData {
    cargo: [];
}

@Component({
    selector: 'app-cargo-select-dialog',
    templateUrl: './cargo-select-dialog.component.html',
    styleUrl: './cargo-select-dialog.component.css'
})
export class CargoSelectDialogComponent {
    cargoFilter = new FormControl('');
    cargoList: any[] = [
    ];

    filteredCargo: any[] = [...this.cargoList];

    constructor(public dialogRef: MatDialogRef<CargoSelectDialogComponent>,
                private storeService: StoreService,
                @Inject(MAT_DIALOG_DATA) public data: CargoDialogData) {

        this.cargoList = data.cargo;

        this.cargoFilter.valueChanges.subscribe(value => {
            this.filteredCargo = this.cargoList.filter(c =>
                c.name.toLowerCase().includes(value.toLowerCase())
            );
        });
        console.log('data', data);
    }

    onSelectCargo(event: any): void {
        const selectedCargo = this.cargoList.find(c => c.name === event.option.value);
        if (selectedCargo) {
            this.dialogRef.close(selectedCargo);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
