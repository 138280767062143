import {FbFormObject} from '../class/fb_form_object';

export class RallySeriesSignup extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'sc_username', label: 'Player', visible: true, noclick: false, disable_ripple: true},
            {id: 'entry_fee_paid', label: 'fee paid', visible: true, noclick: false, disable_ripple: true},
            {id: 'insert_ts', label: 'Signed up', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Signup',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'sc_username',
                        disabled: true,
                    },
                    {
                        type: 'checkbox',
                        name: 'entry_fee_paid',
                        disabled: false
                    },
                    {
                        type: 'datetime',
                        name: 'insert_ts',
                        disabled: true
                    }
                ]
            }
        ];

        super.setEditFields();
    }

    /**
     * override / load all stores we need
     */
    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {

            this.stores['series_signups'] = this.storeService.createNoneGlobalStore('series_signups',
                'race/series/' + String(this.custom_obj.series_id) + '/signups', 'signup_id');

            this.main_store = this.stores['series_signups'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');

            const stores = [];
            // if (!this.stores['sc_version'].isLoaded()) {
            stores.push(this.stores['series_signups'].loadStore(this.filter));
            // }

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        console.log('this.custom_obj', this.custom_obj);
        return {
            data: {
                series_id: this.custom_obj.series_id,
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        // call super
        super.setSelectboxes();
    }
}
