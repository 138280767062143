import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FbFormObject} from '../../../../class/fb_form_object';
import {AppSettings} from '../../../../config/AppSettings';
import {FileUpload2Component} from '../../../../shared/file-upload2/file-upload2.component';
import {StoreService} from '../../../../services/store.service';
import {AuthService} from '../../../../services/auth.service';
import {NotificationService} from '../../../../services/notification.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {ViewportScroller} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {RallySeries} from '../../../../forms/rally-series';
import {FbUtils} from '../../../../class/fb-utils';
import {map} from 'rxjs/operators';
import {RallySeriesTrack} from '../../../../forms/rally-series-track';
import {RallySeriesSignup} from '../../../../forms/rally-series-signup';
import {Store} from '../../../../class/store';
import {SeriesPoint} from '../../../../forms/series-point';

@Component({
    selector: 'app-series-details',
    templateUrl: './series-details.component.html',
    styleUrls: ['./series-details.component.css']
})
export class SeriesDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('select_caption_tpl', {static: true}) set select_caption_tpl(value: ElementRef) {
        this._select_caption_tpl = value;
        this.cdr.markForCheck();
    }

    @ViewChild('upload_tpl', {static: true}) public upload_tpl: ElementRef;
    @ViewChild('header_tpl', {static: true}) public header_tpl: ElementRef;

    public loading_text = '';
    public is_loading = true;
    public fields: any[];
    public mode = 'edit';
    public edit = false;
    public owner = false;
    public rows = [];
    public count = 0;
    public toc_summary: any[];
    public toc_content: any[];

    private subs: Array<Subscription> = [];
    public record: any;
    public id: string | number = '';
    private storeObj: FbFormObject;

    private _select_caption_tpl: ElementRef;
    public is_admin: boolean | undefined;

    public files = [];
    public max_file_count = 1;
    public url_file_store = AppSettings.getRealAPILink() + 'media';
    public media_path: string = AppSettings.getRealAPILink();
    public bgStyle: any;
    public storeAssignedObj: FbFormObject;
    public storeSignupsObj: FbFormObject;
    public tracks: any[] | undefined;
    public signups: any[] | undefined;
    public seriesSignups: Store;
    public bgUrl = '';
    public isSignupAllowed = false;
    public isSignedUp = false;
    public formSeriesPoints: FbFormObject;

    private file_upload: FileUpload2Component;


    constructor(private storeService: StoreService,
                private authService: AuthService,
                private notificationService: NotificationService,
                private route: ActivatedRoute,
                private cdr: ChangeDetectorRef,
                public dialog: MatDialog,
                private http: HttpClient,
                private scroller: ViewportScroller,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {

        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_rally_admin);


        this.subs.push(this.route.params.subscribe((params: any) => {
            if (params.id && params.id !== 'add') {
                this.mode = 'edit';
                this.id = params.id;
            } else {
                this.mode = 'add';
                // this.edit = true;
            }

            const filter = [];
            filter.push('filter[series_id][0][mode]=equals');
            filter.push('filter[series_id][0][operator]=and');
            filter.push('filter[series_id][0][value]=' + this.id);
            filter.push('first=0');
            filter.push('rows=1');

            this.storeObj = new RallySeries(this.storeService, this.authService, this.mode);
            this.storeObj.init({select_caption_tpl: this._select_caption_tpl, upload_tpl: this.upload_tpl}, {},
                '?' + filter.join('&')).then((success) => {
                this.fields = this.storeObj.getEditFields();
                if (this.id && this.id !== '' && this.mode === 'edit') {
                    const rec = this.storeObj.getStore().getRecordById(this.id);
                    if (rec) {
                        this.record = rec;
                        const url = this.media_path + 'media/' + this.record.data.file_id + '/show';
                        this.bgStyle = {'background-image': 'url(' + url + ')'};

                        this.storeAssignedObj = new RallySeriesTrack(this.storeService, this.authService, 'edit');
                        this.storeSignupsObj = new RallySeriesSignup(this.storeService, this.authService, 'edit');
                        this.formSeriesPoints = new SeriesPoint(this.storeService, this.authService, 'edit');
                        if (!this.edit) {
                            const seriesTracks = this.storeService.createNoneGlobalStore('assigned_series_track_' + this.id,
                                'race/series/' + this.id + '/tracks-overview', 'assign_id');
                            seriesTracks.loadStore('?first=0&no_limit=1').then((records: any[]) => {
                                this.tracks = records;
                            });

                            this.seriesSignups = this.storeService.createNoneGlobalStore('series_signups_' + this.id,
                                'race/series/' + this.id + '/signups', 'signup_id');
                            this.loadSignups();
                        }
                    }
                } else {
                    this.resetModel();
                }
                this.rebuildForm();
                this.is_loading = false;
                this.cdr.detectChanges();
            });

            this.bgUrl = this.media_path + 'media/';


        }));
    }

    private loadSignups() {
        this.seriesSignups.loadStore('?first=0&no_limit=1').then((records: any[]) => {
            this.signups = records;
            this.signupAllowed();
        });
    }

    public rebuildForm() {
        this.fields = [];
        this.cdr.detectChanges();
        this.fields = this.storeObj.getForm(this.record.data);
        this.cdr.detectChanges();
    }

    private resetModel() {
        this.record = this.storeObj.getDefaultModel();
        this.record.data.user_id = this.authService.getUserId();
    }

    public saveStore(event: any) {
        if (this.mode === 'edit') {
            this.storeObj.getStore().patchRecord(this.record, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.edit = false;
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        } else {
            this.storeObj.getStore().addRecord(this.record.data, '').then((res) => {
                if (res && res.success) {
                    this.notificationService.success('Data',
                        'Speichern erfolgreich.', {timeOut: 5000});
                    this.resetModel();
                    this.rebuildForm();
                } else {
                    this.notificationService.error('Data',
                        'Speichern fehlgeschlagen.', {timeOut: 5000});
                }
            });
        }
    }

    public signup() {
        this.seriesSignups.addRecord({series_id: this.id}, '').then((res) => {
            if (res && res.success) {
                this.notificationService.success('Signup',
                    'Signup successful.', {timeOut: 5000});
                this.loadSignups();
                this.edit = false;
            } else {
                this.notificationService.error('Signup',
                    'Signup failed.', {timeOut: 5000});
            }
        });
    }

    public signupAllowed() {

        const dt = new Date();
        if (new Date(this.record.data.signup_start) <= dt && new Date(this.record.data.signup_end) >= dt) {
            this.isSignupAllowed =  true;
        } else {
            this.isSignupAllowed = false;
        }

        if (this.signups && this.signups.length > 0 && this.authService.getUserId() > 0) {
            for (let i = 0; i < this.signups.length; i++) {
                const row = this.signups[i];
                if (String(row.data.user_id) === String(this.authService.getUserId())) {
                    this.isSignupAllowed = false;
                    this.isSignedUp = true;
                }
            }
        }
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
        if (this.mode === 'edit') {
            this.storeObj.getStore().revertRecord(this.record);
        }
    }

    public scrollTo(target: string) {
        document.getElementById(target).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    public onFileuploadInit(file_upload: FileUpload2Component) {
        this.file_upload = file_upload;
    }

    public onFileUpload($event, type, element) {
        this.record.data.file_id = $event.body.rows[0].file_id;
        if ($event.ok) {
            // this.FileStore.reloadStore('').then(records => {
            this.notificationService.success('Daten',
                'Speichern erfolgreich.', {timeOut: 5000});
            // });
        } else {
            this.notificationService.error('Daten',
                'Speichern fehlgeschlagen.', {timeOut: 5000});
            this.cdr.detectChanges();
        }
    }

    enterEditMode() {
        this.edit = true;
        this.cdr.detectChanges();
    }

    deleteFile(file_id: number) {

        return this.http.delete(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'media/' + file_id)).pipe(
            map((user: any) => {
            })
        ).subscribe((result) => {
            this.record.data.file_id = 0;
        });
    }
}
