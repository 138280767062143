import {FbFormObject} from '../class/fb_form_object';
import {FormInputsComponent} from '../shared/form-inputs/form-inputs.component';
import {AppSettings} from '../config/AppSettings';
import {HttpClient, HttpHeaders} from '@angular/common/http';

export class ScContractForm extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'type', label: 'type', visible: true, noclick: false, disable_ripple: true},
            {id: 'title', label: 'title', visible: true, noclick: false, disable_ripple: true},
            {id: 'description', label: 'description', visible: true, noclick: false, disable_ripple: true},
            // {id: 'start_ts', label: 'start_ts', visible: true, noclick: false, disable_ripple: true},
            {id: 'deadline_ts', label: 'deadline_ts', visible: true, noclick: false, disable_ripple: true},
            {id: 'contractor_only', label: 'contractor_only', visible: true, noclick: false, disable_ripple: true},
            {id: 'contractor', label: 'contractor', visible: true, noclick: false, disable_ripple: true},
            {id: 'client', label: 'client', visible: true, noclick: false, disable_ripple: true},
            {id: 'status', label: 'status', visible: true, noclick: false, disable_ripple: true},
            {id: 'is_private', label: 'private', visible: true, noclick: false, disable_ripple: true},
            {id: 'add_to_inventory', label: 'add to warehouse', visible: true, noclick: false, disable_ripple: true},
            {id: 'copy', label: 'copy', visible: true, noclick: true, disable_ripple: true},
            // {id: 'event_contract', label: 'event based', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Contract',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'select',
                        name: 'type',
                        options: this.select_boxes['type_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        on_init: (field: any, inputCls: FormInputsComponent) => {
                            if (field.model.obj.type !== '') {
                                field.disabled = true;
                            } else {
                                field.disabled = false;
                            }
                        },
                    },
                    {
                        type: 'text',
                        name: 'title',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'textarea',
                        name: 'description',
                    },
                    // {
                    //     type: 'datetime',
                    //     name: 'start_ts',
                    //     rules: [
                    //         {rule: 'required'}
                    //     ]
                    // },
                    {
                        type: 'datetime',
                        name: 'deadline_ts',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'checkbox',
                        name: 'contractor_only',
                    },
                    {
                        type: 'autocomplete',
                        name: 'contractor',
                        options: this.select_boxes['ifa_member_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        on_init: (field: any, inputCls: FormInputsComponent) => {
                            if (field.model.obj.contractor_only === true
                                || Number(field.model.obj.contractor_only) === 1) {
                                field.disabled = false;
                            } else {
                                field.disabled = true;
                            }
                        },
                        on_other_field_changed: (self: any, changed_field: any,
                                                 setFieldValueCallback: any, refreshFormField: any, that: any) => {
                            if (changed_field.name === 'contractor_only') {
                                if (changed_field.last_value) {
                                    that.form.controls[self.name].control.disable();
                                } else {
                                    that.form.controls[self.name].control.enable();
                                    setFieldValueCallback(self, '');
                                }
                            }
                        }
                    },
                    {
                        type: 'autocomplete',
                        name: 'client',
                        options: this.select_boxes['ifa_member_select'],
                        rules: [
                            {rule: 'required'}
                        ],
                        on_init: (field: any, inputCls: FormInputsComponent) => {
                            if (field.model.obj.is_private === true
                                || Number(field.model.obj.is_private) === 1) {
                                field.disabled = true;
                            } else {
                                field.disabled = false;
                            }
                        },
                    },
                    {
                        type: 'select',
                        name: 'status',
                        options: this.select_boxes['status_select'],
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'checkbox',
                        name: 'is_private',
                        disabled: this.custom_obj.is_private
                    },
                    {
                        type: 'checkbox',
                        name: 'add_to_inventory',
                        restricted_groups: [AppSettings.group_contract_admin, AppSettings.group_page_admin],
                        disabled: !this.custom_obj.is_private,
                        on_init: (field: any, inputCls: FormInputsComponent) => {
                            if (field.model.obj.is_private === true
                                || Number(field.model.obj.is_private) === 1) {
                                field.disabled = true;
                            } else {
                                field.disabled = false;
                            }
                        },
                        on_other_field_changed: (self: any, changed_field: any,
                                                 setFieldValueCallback: any, refreshFormField: any, that: any) => {
                            if (changed_field.name === 'is_private') {
                                if (that.form.controls[self.name]) {
                                    if (changed_field.model.obj.is_private === true) {
                                        that.form.controls[self.name].control.disable();
                                        setFieldValueCallback(self, false);
                                    } else {
                                        that.form.controls[self.name].control.enable();
                                    }
                                }
                            }
                        }
                    },
                    {
                        type: 'button',
                        name: 'copy',
                        color: 'secondary',
                        class: 'button-add',
                        onClick: ($event, model) => {
                            this.cloneContract(model.c_id).then((result) => {
                                if (result.success) {
                                    this.custom_obj.button_callback(result.record.c_id);
                                }
                            });
                        },
                    },
                    // {
                    //     type: 'checkbox',
                    //     name: 'event_contract',
                    //     restricted_groups: [AppSettings.group_contract_admin, AppSettings.group_page_admin],
                    //     disabled: !this.custom_obj.is_private,
                    //     on_init: (field: any, inputCls: FormInputsComponent) => {
                    //         if (field.model.obj.is_private === true
                    //             || Number(field.model.obj.is_private) === 1) {
                    //             field.disabled = true;
                    //         } else {
                    //             field.disabled = false;
                    //         }
                    //     },
                    //     on_other_field_changed: (self: any, changed_field: any,
                    //                              setFieldValueCallback: any, refreshFormField: any, that: any) => {
                    //         if (changed_field.name === 'is_private') {
                    //             if (that.form.controls[self.name]) {
                    //                 if (changed_field.model.obj.is_private === true) {
                    //                     that.form.controls[self.name].control.disable();
                    //                     setFieldValueCallback(self, false);
                    //                 } else {
                    //                     that.form.controls[self.name].control.enable();
                    //                 }
                    //             }
                    //         }
                    //     }
                    // },
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['sc_contracts'] = this.storeService.getStore('sc_contracts');

            this.main_store = this.stores['sc_contracts'];

            this.stores['ifa_member'] = this.storeService.getStore('ifa_member');

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');

            const stores = [];
            stores.push(this.stores['sc_contracts'].loadStore(this.filter));
            stores.push(this.stores['ifa_member'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                type: '',
                title: '',
                description: '',
                // start_ts: null,
                deadline_ts: null,
                contractor_only: false,
                contractor: '',
                client: this.authService.getUser().details.user_id,
                status: 'draft',
                is_private: this.custom_obj.is_private,
                add_to_inventory: !this.custom_obj.is_private,
                event_contract: !this.custom_obj.is_private
            }
        };
    }

    /**
     * custom reload
     */
    public setSelectboxes() {
        this.parseTypeSelectBox();
        this.parseScUserSelectbox();
        this.parseStatusSelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseTypeSelectBox(): any[] {
        const options = [
            {
                value: 'acquisition',
                name: 'acquisition'
            },
            {
                value: 'escort',
                name: 'escort'
            },
            {
                value: 'trade',
                name: 'trade'
            },
            // {
            //     value: 'transportation',
            //     name: 'transportation'
            // }
        ];
        this.select_boxes['type_select'] = options;
        return this.select_boxes['type_select'];
    }

    public parseStatusSelectBox(): any[] {
        const options = [
            {
                value: 'draft',
                name: 'draft'
            },
            {
                value: 'published',
                name: 'published'
            },
            {
                value: 'taken',
                name: 'taken'
            },
            {
                value: 'turned-in',
                name: 'turned-in'
            },
            {
                value: 'confirmed',
                name: 'confirmed'
            },
            {
                value: 'revoked',
                name: 'revoked'
            }
        ];
        this.select_boxes['status_select'] = options;
        return this.select_boxes['status_select'];
    }

    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['ifa_member'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['ifa_member_select'] = options;
        return this.select_boxes['ifa_member_select'];
    }

    public cloneContract(c_id: string): Promise<any> {
        const httpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json');
        const options = {
            headers: httpHeaders,
            withCredentials: true
        };

        return new Promise<any>((resolve, reject) =>  {
            this.getStore().showSaveScreen();
            const url = AppSettings.getRealAPILink() + 'sc-contracts/' + c_id + '/clone';
            const httpClient: HttpClient = this.custom_obj.http;
            const postObs = httpClient.post<any>(url, {}, options);
            postObs.subscribe(
                response => {
                    let records = [];
                    if (response.success) {
                        records = response.rows;
                    } else {
                        // old api
                        records = response;
                    }
                    this.getStore().hideSaveScreen();
                    resolve({success: true, record: response.rows[0]});
                },
                err => {
                    console.log(err);
                    this.getStore().hideSaveScreen();
                    resolve(false);
                }
            );
        });
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

}
