<div>
    <app-content-container [content_template]="page_content"
                           [is_loading]="is_loading"
                           [loading_text]="loading_text" [custom_class]="'no-padding'"></app-content-container>
</div>
<ng-template #page_content>
    <div class="padding">
        <div class="table-container">
            <div class="card card-1 padding filter-grid">
                <mat-form-field>
                    <mat-label>max locations per commodity</mat-label>
                    <input matInput maxlength="2"
                           type="number"
                           placeholder="max locations per commodity"
                           [(ngModel)]="filter.max_locations"
                           (change)="filterChanged($event, 'max_locations')"
                    >
                </mat-form-field>

                <mat-form-field>
                    <mat-label>sort price</mat-label>
                    <mat-select
                            [(ngModel)]="filter.sort_price"
                            (selectionChange)="priceValueChanged($event, 'price_value')">
                        <mat-option value="price_sell">price sell</mat-option>
                        <mat-option value="price_sell_avg">price sell avg</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>systems</mat-label>
                    <mat-select
                            [(ngModel)]="filter.systems"
                            (selectionChange)="systemValueChanged($event, 'systems')"
                            multiple>
                        <mat-option value="Stanton">Stanton</mat-option>
                        <mat-option value="Pyro">Pyro</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>max sell locations</mat-label>
                    <input matInput maxlength="2"
                           type="number"
                           placeholder="max sell locations"
                           [(ngModel)]="filter.max_sell_locations"
                           (change)="filterChanged($event, 'max_sell_locations')"
                    >
                </mat-form-field>

            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!-- Dynamic Column Definition -->
                <ng-container *ngFor="let column of columns" [matColumnDef]="column.key">
                    <th mat-header-cell *matHeaderCellDef> {{ column.label }} </th>
                    <td mat-cell *matCellDef="let element">
                        @if (column.type === 'location') {
<!--                            {{element.data.}}-->
                            @if (element.price_data[column.key]) {
                                <div
                                    appTableTooltip [data]="element.price_data[column.key]" [columns]="tool_tip_columns">
                                {{Math.round(element.price_data[column.key][filter.sort_price])}}
                                </div>
                            } @else {
                             -
                            }

                            <!--
commodity_name
container_sizes
date_added
date_modified
id_commodity
id_terminal
last_update
price_buy
price_buy_avg
price_sell
price_sell_avg
scu_buy
scu_buy_avg
scu_sell
scu_sell_avg
scu_sell_stock
scu_sell_stock_avg
status_buy
status_sell
terminal_name
                            -->
                        } @else if (column.type === 'default') {
                            {{ element[column.key] || '-' }}
                        } @else if (column.type === 'remove') {
                            <button mat-raised-button color="primary"
                                    (click)="removeRow(element)"
                                    class="button-delete remove-cargo-button">remove</button>
                        } @else if (column.type === 'container_select') {
                            <mat-form-field>
                                <mat-label>max container size</mat-label>
                                <mat-select
                                        [(ngModel)]="element.biggest_container"
                                        (selectionChange)="ContainerValueChanged($event, 'biggest_container')">
                                    @for (value of [1, 2, 4, 8, 16, 24, 32]; track value) {
                                        <mat-option [value]="value">{{ value }}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        } @else if (column.type === 'amount_input') {
                            <mat-form-field>
                                <mat-label>amount</mat-label>
                                <input matInput maxlength="2"
                                       type="number"
                                       placeholder="amount"
                                       [(ngModel)]="element.amount"
                                       (change)="filterChanged($event, 'amount')"
                                >
                            </mat-form-field>
                        }

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <button mat-raised-button color="primary"
                (click)="openAddDialog()"
                class="button-add add-cargo-button">Add Cargo</button>
    </div>
    @if (sell_locations) {
        <div class="padding" style="clear: both;">
            <div class="card card-1 padding ">
                <h2>Best Locations</h2>
                <ul class="clean-list">
                    @for (sell_location of sell_locations.commoditiesSoldPerLocation | keyvalue; track sell_location) {
                        @if (sell_location.value.length > 0) {
                            <li class="location-header">{{sell_location.key}}</li>
                            @for (commodity of sell_location.value; track commodity) {
                                <li>{{commodity.name}} for {{commodity.profit | number:'1.0-0':'de-DE'}} aUEC</li>
                            }
                        }
                    }
                    <li class="total-profit">
                        Total: {{sell_locations.totalProfit | number:'1.0-0':'de-DE'}} aUEC
                    </li>
                </ul>

                @if (sell_locations.unsoldCommodities && sell_locations.unsoldCommodities.length > 0) {
                    <h2>Missing Commodities</h2>
                    <ul class="clean-list">
                        @for (unsold_commodity of sell_locations.unsoldCommodities; track unsold_commodity) {
                            <li>{{unsold_commodity.name}}</li>
                        }
                    </ul>
                }

            </div>
        </div>
    }


</ng-template>
