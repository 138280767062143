import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[appTableTooltip]'
})
export class TableTooltipDirective {
    @Input('data') data: any[] = []; // Data to display in the tooltip
    @Input() columns: any[] = []; // Column names for the table

    private tooltipContainer: HTMLElement | null = null;


    constructor(private el: ElementRef, private renderer: Renderer2) {}

    @HostListener('mouseenter') onMouseEnter() {
        if (this.tooltipContainer) {
            return;
        }

        this.tooltipContainer = this.renderer.createElement('div');
        this.renderer.addClass(this.tooltipContainer, 'tooltip');

        // Create Table
        const table = this.renderer.createElement('table');
        this.renderer.addClass(table, 'tooltip-table');

        // Create Table Header
        const thead = this.renderer.createElement('thead');
        const headerRow = this.renderer.createElement('tr');
        this.columns.forEach(column => {
            const th = this.renderer.createElement('th');
            this.renderer.setProperty(th, 'innerText', column.label);
            this.renderer.appendChild(headerRow, th);
        });
        this.renderer.appendChild(thead, headerRow);
        this.renderer.appendChild(table, thead);

        // Create Table Body
        const tbody = this.renderer.createElement('tbody');
        console.log('row', this.data);
        // this.data.forEach(row => {
        //     const tr = this.renderer.createElement('tr');
        //     this.columns.forEach(column => {
        //         const td = this.renderer.createElement('td');
        //         this.renderer.setProperty(td, 'innerText', row[column] || '-');
        //         this.renderer.appendChild(tr, td);
        //     });
        //     this.renderer.appendChild(tbody, tr);
        // });

        const tr = this.renderer.createElement('tr');
        this.columns.forEach(column => {
            const td = this.renderer.createElement('td');
            this.renderer.setProperty(td, 'innerText', this.data[column.key] || '-');
            this.renderer.appendChild(tr, td);
        });
        this.renderer.appendChild(tbody, tr);

        this.renderer.appendChild(table, tbody);

        this.renderer.appendChild(this.tooltipContainer, table);
        this.renderer.appendChild(document.body, this.tooltipContainer);

        // Position Tooltip
        const rect = this.el.nativeElement.getBoundingClientRect();
        this.renderer.setStyle(this.tooltipContainer, 'top', `${rect.top + window.scrollY + 25}px`);
        this.renderer.setStyle(this.tooltipContainer, 'left', `${rect.left + window.scrollX}px`);
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.tooltipContainer) {
            this.renderer.removeChild(document.body, this.tooltipContainer);
            this.tooltipContainer = null;
        }
    }
}
