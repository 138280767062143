import {FbFormObject} from '../class/fb_form_object';

export class RallySeries extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'series_name', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'description', label: 'Description', visible: true, noclick: false, disable_ripple: true},
            {id: 'series_lead', label: 'Leader', visible: true, noclick: false, disable_ripple: true},
            {id: 'entry_fee', label: 'Entry fee', visible: true, noclick: false, disable_ripple: true},
            {id: 'series_start', label: 'Start', visible: true, noclick: false, disable_ripple: true},
            {id: 'series_end', label: 'End', visible: true, noclick: false, disable_ripple: true},
            {id: 'signup_limit', label: 'Participation limit', visible: true, noclick: false, disable_ripple: true},
            {id: 'signup_start', label: 'Signup start', visible: true, noclick: false, disable_ripple: true},
            {id: 'signup_end', label: 'Signup end', visible: true, noclick: false, disable_ripple: true},
            {id: 'image', label: 'Image', visible: true, type: 'template', template_row: this.templates['upload_tpl'],
                noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Rally Series',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'series_name',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'textarea',
                        name: 'description'
                    },

                    {
                        type: 'autocomplete',
                        name: 'series_lead',
                        options: this.select_boxes['sc_user_select'],
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    },

                    {
                        type: 'number',
                        name: 'entry_fee'
                    },

                    {
                        type: 'datetime',
                        name: 'series_start',
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'datetime',
                        name: 'series_end',
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'template',
                        template: this.templates['upload_tpl'],
                        name: 'image',
                        disabled: true,
                        no_input: false
                    }
                ]
            },
            {
                caption: 'Signup',
                class: 'card card-1 padding',
                field_class: '',
                fields: [
                    {
                        type: 'number',
                        name: 'signup_limit'
                    },
                    {
                        type: 'datetime',
                        name: 'signup_start',
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'datetime',
                        name: 'signup_end',
                        disabled: false,
                        rules: [
                            {rule: 'required'}
                        ]
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['rally_series'] = this.storeService.getStore('rally_series');
            this.stores['sc_user'] = this.storeService.getStore('sc_user');

            this.main_store = this.stores['rally_series'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');

            const stores = [];
            stores.push(this.stores['rally_series'].loadStore(this.filter));
            stores.push(this.stores['sc_user'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                track_name: '',
                description: '',
                file_id: 0
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseScUserSelectbox();
        // call super
        super.setSelectboxes();
    }

    public parseScUserSelectbox() {
        const options = [];
        const records = this.stores['sc_user'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.user_id),
                name: records[i].data.sc_username
            });
        }
        this.select_boxes['sc_user_select'] = options;
        return this.select_boxes['sc_user_select'];
    }
}
