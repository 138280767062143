import {FbFormObject} from '../class/fb_form_object';

export class StoreLocation extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'location', label: 'Name', visible: true, noclick: false, disable_ripple: true},
            {id: 'sc_system', label: 'System', visible: true, noclick: false, disable_ripple: true},
            {id: 'wiki_link', label: 'Wiki-link', visible: true, noclick: false, disable_ripple: true},
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        this.edit_fields = [
            {
                caption: 'Location',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'text',
                        name: 'location',
                        rules: [
                            {rule: 'required'}
                        ]
                    },
                    {
                        type: 'autocomplete',
                        name: 'sc_system',
                        options: this.select_boxes['sc_systems_select']
                    },
                    {
                        type: 'text',
                        name: 'wiki_link',
                    }
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['store_locations'] = this.storeService.getStore('store_locations');
            this.stores['sc_systems'] = this.storeService.getStore('sc_systems');
            this.main_store = this.stores['store_locations'];

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');

            const stores = [];
            stores.push(this.stores['store_locations'].loadStore(this.filter));
            stores.push(this.stores['sc_systems'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                location: '',
                sc_system: '',
                wiki_link: ''
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseScSystemSelectbox();
        // call super
        super.setSelectboxes();
    }

    public parseScSystemSelectbox() {
        const options = [];
        const records = this.stores['sc_systems'].getRecords();
        for (let i = 0; i < records.length; i++) {
            options.push({
                value: String(records[i].data.sc_system),
                name: records[i].data.sc_system
            });
        }
        this.select_boxes['sc_systems_select'] = options;
        return this.select_boxes['sc_systems_select'];
    }
}
