import {FbFormObject} from '../class/fb_form_object';

export class Manufacturer extends FbFormObject {
    /**
     * override default columns
     */
    public setColumns() {
        this.column_definition = [
            {id: 'man_short', label: 'Code', visible: true, noclick: false, disable_ripple: true},
            {id: 'man_name', label: 'Name', visible: true, noclick: false, disable_ripple: true}
        ];
    }

    /**
     * override default fields
     */
    public setEditFields() {
        console.log('templates', this.templates);
        this.edit_fields = [
            {
                caption: 'Track',
                class: 'card card-1 padding single-column',
                field_class: '',
                fields: [
                    {
                        type: 'autocomplete',
                        name: 'man_short',
                        options: this.select_boxes['fy_manufacturers_select'],
                        template: this.templates['man_short_select_tpl'] ? this.templates['man_short_select_tpl'] : null,
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_code') {
                                if (changed_field.model.obj.fy_code !== '') {
                                    setFieldValueCallback(self, changed_field.model.obj.fy_code);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                    {
                        type: 'text',
                        name: 'man_name',
                        rules: [
                            {rule: 'required'}
                        ],
                        on_other_field_changed: (self, changed_field, setFieldValueCallback, refreshFormField) => {
                            if (changed_field.name === 'fy_code') {
                                if (changed_field.model.obj.fy_id !== '') {
                                    const record = this.stores['fy_manufacturers'].getRecordById(changed_field.model.obj.code);
                                    setFieldValueCallback(self, record.data.longName);
                                } else {
                                    setFieldValueCallback(self, '');
                                }
                            }
                        },
                    },
                ]
            }
        ];
        super.setEditFields();
    }

    public loadStores(ext_obj?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.stores['manufacturer'] = this.storeService.getStore('manufacturer');

            this.main_store = this.stores['manufacturer'];

            this.stores['fy_manufacturers'] = this.storeService.getStore('fy_manufacturers');

            const selectFilter = [];
            selectFilter.push('first=0');
            selectFilter.push('no_limit=1');

            const stores = [];
            stores.push(this.stores['manufacturer'].loadStore(this.filter));
            stores.push(this.stores['fy_manufacturers'].loadStore('?' + selectFilter.join('&')));

            Promise.all(stores).then((result) => {
                resolve(true);
            });
        });
    }

    public getDefaultModel() {
        return {
            data: {
                man_name: '',
                man_short: ''
            }
        };
    }

    /**
     * custom reload
     */
    public reload(): Promise<any> {
        return this.getStore().loadStore('');
    }

    /**
     * override select_boxes
     */
    public setSelectboxes() {
        this.parseFyManufacturersSelectBox();
        // call super
        super.setSelectboxes();
    }

    public parseFyManufacturersSelectBox() {
        const options = [];
        const records = this.stores['fy_manufacturers'].getRecords();
        for (let i = 0; i < records.length; i++) {
            if (records[i].data.hasOwnProperty('code')) {
                options.push({
                    value: String(records[i].data.code),
                    name: records[i].data.code,
                    longName: records[i].data.longName
                });
            }
        }
        this.select_boxes['fy_manufacturers_select'] = options;
        return this.select_boxes['fy_manufacturers_select'];
    }

}
