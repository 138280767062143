import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {StoreService} from '../../../services/store.service';
import {Router} from '@angular/router';
import {AppSettings} from '../../../config/AppSettings';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {CargoSelectDialogComponent} from '../../../shared/dialoge/cargo-select-dialog/cargo-select-dialog.component';
import {Store} from '../../../class/store';
import {MatSelectChange} from '@angular/material/select';

@Component({
    selector: 'app-cargo-sell-planer',
    templateUrl: './cargo-sell-planer.component.html',
    styleUrl: './cargo-sell-planer.component.css'
})
export class CargoSellPlanerComponent implements OnInit {

    public loading_text = '';
    public is_loading = true;
    public menu = [];
    public is_admin = false;
    public is_logged_in = false;
    public is_ifa = false;

    public displayedColumns: string[] = [];
    public dataSource = new MatTableDataSource<any>([]);

    protected readonly Math = Math;

    private session_key = 'cargo-sell-planer';

    public filter = {
        max_locations: 5,
        sort_price: 'price_sell',
        max_sell_locations: 4,
        systems: ['Stanton', 'Pyro']
    };

    private selected_rows = [];

    public tool_tip_columns = [
        {key: 'container_sizes', label: 'Container'},
        {key: 'price_sell', label: 'Price Sell'}
    ];

    private cargoStore: Store;
    private priceStore: Store;
    private terminalStore: Store;

    private locations = {};

    private base_columns = [
        {key: 'remove', label: 'remove', type: 'remove'},
        // {key: 'uex_id', label: 'ID', type: 'default'},
        {key: 'name', label: 'Name', type: 'default'},
        {key: 'amount_input', label: 'Amount', type: 'amount_input'},
        {key: 'container_select', label: 'Container', type: 'container_select'}
    ];
    public columns = [];

    public sell_locations = {};

    private commodity_list = [];

    constructor(private authService: AuthService,
                private storeService: StoreService,
                public router: Router,
                public dialog: MatDialog) {
        this.is_logged_in = this.authService.getLoginState() === 2;
        this.is_ifa = this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_ifa);
    }

    private loadInitialCommodityList() {
        this.commodity_list = [];
        if (this.selected_rows && this.selected_rows.length > 0) {
            for (let i = 0; i < this.selected_rows.length; i++) {
                const row = this.selected_rows[i];
                this.commodity_list.push({com_id: row.com_id, biggest_container: row.biggest_container, amount: row.amount});
            }
        }
    }

    ngOnInit() {
        this.is_admin = this.authService.isAdmin() || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_page_admin)
            || this.authService.hasRole(AppSettings.ifa_guild_id, AppSettings.group_event_admin);
        this.refreshColumns();
        this.loadStores();
    }

    private refreshColumns() {
        const columns = [ ...this.base_columns ];
        // columns.push({key: 'test', label: 'Test'});


        Object.keys(this.locations).forEach(key => {
            columns.push({key: key, label: key, type: 'location'});
        });


        this.columns = columns;

        const displayedColumns = [];
        for (let i = 0; i < this.columns.length; i++) {
            displayedColumns.push(this.columns[i].key);
        }
        this.displayedColumns = displayedColumns;
    }

    openAddDialog(): void {
        const records = this.cargoStore.getRecords();
        const cargo = [];
        for (let i = 0; i < records.length; i++) {
            cargo.push(
                {
                    com_id: records[i].data['com_id'],
                    uex_id: records[i].data['uex_id'],
                    name: records[i].data['name']
                }
            );
        }
        const dialogRef = this.dialog.open(CargoSelectDialogComponent, {
            width: '300px',
            data: {cargo: cargo}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const selectedCargo = this.commodity_list.find(row => row.com_id === result.com_id);
                if (selectedCargo) {
                    return;
                }
                this.commodity_list.push({com_id: result.com_id, biggest_container: 32, amount: 1});
                this.rebuildTable();
            }
        });
    }

    private rebuildTable() {

        let old_data = this.dataSource.data;

        if (old_data.length === 0) {
            old_data = this.selected_rows;
        }
        this.dataSource.data = [];

        this.locations = [];

        this.selected_rows = [];

        for (let i = 0; i < this.commodity_list.length; i++) {

            const commodity = this.cargoStore.getRecordById(this.commodity_list[i].com_id);

            const row =  {
                com_data: commodity,
                price_data: {},
                uex_id: commodity.data.uex_id,
                name: commodity.data.name,
                locations: [],
                biggest_container: old_data[i] ? old_data[i].biggest_container : 32,
                amount: old_data[i] ? old_data[i].amount : 1,
            };
            this.addNewLocationsForCommodity(commodity.data.uex_id, row);

            this.selected_rows.push({
                com_id: this.commodity_list[i].com_id,
                biggest_container: row.biggest_container,
                amount: row.amount
            });

            this.dataSource.data = [...this.dataSource.data, row];
        }
        this.refreshColumns();

        this.saveSession();

        this.sell_locations = this.getBestSellingLocations(this.dataSource.data, this.filter.max_sell_locations);
    }

    private saveSession() {
        localStorage.setItem(this.session_key, JSON.stringify(
            {
                filter: this.filter,
                selected_rows: this.selected_rows
            }
        ));
    }

    private addNewLocationsForCommodity(uex_id: number, row: any) {
        const records = this.priceStore.getRecordsByKeyArray([
            {key: 'id_commodity', value: uex_id}
        ]);

        records.sort((a, b) => b.data[this.filter.sort_price] - a.data[this.filter.sort_price]);

        let used_terminal_count = 0;

        for (let i = 0; i < records.length; i++) {
            const rec = records[i];

            const terminal = this.terminalStore.getRecordById(rec.data.id_terminal);

            if (!terminal) {
                continue;
            }

            if (!this.filter.systems.includes(terminal.data.star_system_name)) {
                continue;
            }

            const max_container_size  = Math.max(...rec.data.container_sizes.split(',').map(Number));
            if (max_container_size < row.biggest_container) {
                continue;
            }

            // do not add more as the max locations per commodity but allow it, for already added locations
            if (used_terminal_count > this.filter.max_locations && !this.locations[rec.data.terminal_name]) {
                continue;
            }
            used_terminal_count++;

            this.locations[rec.data.terminal_name] = rec.data.terminal_name;
            row['locations'].push({
                name: rec.data.terminal_name,
                price: rec.data[this.filter.sort_price],
            });
            row['price_data'][rec.data.terminal_name] = rec.data;
        }
    }

    private loadStores() {
        this.priceStore = this.storeService.createNoneGlobalStore('price_details',
            'uex/commodity-averages-all/price_details', 'id');

        this.cargoStore = this.storeService.createNoneGlobalStore('cargo',
            'games/starcitizen/possible-commodities/cargo', 'com_id');

        this.terminalStore = this.storeService.createNoneGlobalStore('terminals',
            'uex/terminals', 'id');

        const stores = [];
        // if (!this.stores['sc_events'].isLoaded()) {
        stores.push(this.priceStore.loadStore('?no_limit=1'));
        stores.push(this.cargoStore.loadStore('?no_limit=1'));
        stores.push(this.terminalStore.loadStore('?no_limit=1'));

        Promise.all(stores).then((result) => {

            // load rows from session
            const ls_value = localStorage.getItem(this.session_key);
            if (ls_value) {
                const session_obj = JSON.parse(ls_value);
                this.filter = session_obj.filter;
                this.selected_rows = session_obj.selected_rows;
                this.loadInitialCommodityList();
                this.rebuildTable();
            }
            this.is_loading = false;
        });
    }

    removeRow(rowToRemove: any) {
        this.commodity_list = this.commodity_list.filter(com => com.com_id !== rowToRemove.com_data.data.com_id);
        this.rebuildTable();
    }

    filterChanged($event: Event, type: string) {
        this.rebuildTable();
    }

    priceValueChanged($event: MatSelectChange, priceValue: string) {
        this.rebuildTable();
    }

    ContainerValueChanged($event: MatSelectChange, value: string) {
        this.rebuildTable();
    }

    systemValueChanged($event: MatSelectChange, systems: string) {
        this.rebuildTable();
    }


    getBestSellingLocations(commodities: any[], maxLocations: number) {
        const locationProfits: Record<string, number> = {};
        const commoditiesSoldPerLocation: Record<string, { uex_id: number; name: string; profit: number }[]> = {};
        const unsoldCommodities: { uex_id: number; name: string }[] = [];

        // Identify valid locations for each commodity
        const validLocations = new Set<string>();
        commodities.forEach(commodity => {
            commodity.locations.forEach(location => {
                if (parseFloat(location.price) > 0) {
                    validLocations.add(location.name);
                }
            });
        });

        // Convert valid locations to array and get the best combination
        const locationList = Array.from(validLocations);
        const bestCombination = this.findBestLocationCombination(commodities, locationList, maxLocations);

        // Determine unsold commodities
        const soldCommodities = new Set<number>();
        bestCombination.locations.forEach(location => {
            (bestCombination.commoditiesSoldPerLocation[location] || []).forEach(item => {
                soldCommodities.add(item.uex_id);
            });
        });

        commodities.forEach(commodity => {
            if (!soldCommodities.has(commodity.uex_id)) {
                unsoldCommodities.push({ uex_id: commodity.uex_id, name: commodity.name });
            }
        });

        return { ...bestCombination, unsoldCommodities };
    }

    findBestLocationCombination(commodities: any[], locations: string[], maxLocations: number) {
        let bestTotalProfit = 0;
        let bestLocations: string[] = [];
        let bestCommodityAssignment: Record<string, { uex_id: number; name: string; profit: number }[]> = {};

        function getProfitForCombination(selectedLocations: string[]) {
            let totalProfit = 0;
            const commodityAssignment: Record<string, { uex_id: number; name: string; profit: number }[]> = {};
            const assignedCommodities = new Set<number>();

            for (const commodity of commodities) {
                let bestLocation = null;
                let bestProfit = 0;

                for (const location of selectedLocations) {
                    const validLocation = commodity.locations.find(l => l.name === location
                        && parseFloat(l.price) > 0);
                    if (validLocation) {
                        const profit = parseFloat(validLocation.price) * commodity.amount;
                        if (profit > bestProfit) {
                            bestProfit = profit;
                            bestLocation = location;
                        }
                    }
                }

                if (bestLocation) {
                    totalProfit += bestProfit;
                    if (!commodityAssignment[bestLocation]) {
                        commodityAssignment[bestLocation] = [];
                    }
                    commodityAssignment[bestLocation].push({ uex_id: commodity.uex_id, name: commodity.name, profit: bestProfit });
                    assignedCommodities.add(commodity.uex_id);
                }
            }
            return { totalProfit, commodityAssignment };
        }

        function generateCombinations(start: number, chosen: string[]) {
            if (chosen.length > maxLocations) {
                return;
            }
            const { totalProfit, commodityAssignment } = getProfitForCombination(chosen);
            if (totalProfit > bestTotalProfit) {
                bestTotalProfit = totalProfit;
                bestLocations = [...chosen];
                bestCommodityAssignment = commodityAssignment;
            }
            for (let i = start; i < locations.length; i++) {
                generateCombinations(i + 1, [...chosen, locations[i]]);
            }
        }

        generateCombinations(0, []);

        return {
            locations: bestLocations,
            totalProfit: bestTotalProfit,
            commoditiesSoldPerLocation: bestCommodityAssignment
        };
    }
}
