import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreService} from '../../../services/store.service';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-member-profile',
    templateUrl: './member-profile.component.html',
    styleUrls: ['./member-profile.component.css']
})
export class MemberProfileComponent implements OnInit, OnDestroy, AfterViewInit {


    public loading_text = '';
    public is_loading = true;
    public fields: any[];

    private subs: Array<Subscription> = [];
    public id: string | number = '';
    public badges: any[];

    public branches = {
        any: 'Any',
        exploration: 'Exploration',
        science: 'Science',
        medical: 'Medical',
        defence_fps: 'Defence FPS',
        defence_flight: 'Defence Flight',
        industries: 'Industries',
        logistics: 'Logistics'
    };

    constructor(private storeService: StoreService,
                private authService: AuthService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.subs.push(this.route.params.subscribe((params: any) => {
            if (params && params.user_id) {
                const store = this.storeService.createNoneGlobalStore('event-cargo-delivery',
                    'ifa/members/' + params.user_id + '/badges-assigned',
                    'id');
                store.loadStore('').then((badges: any[]) => {
                    this.createBadgeObject(badges);
                    this.is_loading = false;
                });
            }
        }));
    }

    public createBadgeObject(badges: any[]) {
        if (badges && badges.length > 0) {
            const tmpBadges = {};
            for (let i = 0; i < badges.length; i++) {
                const badge = badges[i];
                if (!tmpBadges[badge.data.badge_type]) {
                    tmpBadges[badge.data.badge_type] = {ranks: [], medals: []};
                }
                if (badge.data.badge_is_medal) {
                    tmpBadges[badge.data.badge_type].medals.push(badge.data);
                } else {
                    tmpBadges[badge.data.badge_type].ranks.push(badge.data);
                }
            }

            const arBadges = Object.keys(tmpBadges).map(function(namedIndex) {
                const branch = tmpBadges[namedIndex];
                // do something with person
                branch['branch'] = namedIndex;
                branch.ranks.sort((a, b) => b.badge_rank - a.badge_rank);
                branch.medals.sort((a, b) => a.badge_name - b.badge_name);
                return branch;
            });
            console.log('arBadges', arBadges);
            this.badges = arBadges;
        }
    }


    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

}
