import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FbUtils} from '../../class/fb-utils';
import {AppSettings} from '../../config/AppSettings';
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {Router} from '@angular/router';
import {Store} from '../../class/store';
import {StoreService} from '../../services/store.service';
import {AuthService} from '../../services/auth.service';
@Component({
    selector: 'app-contract-tile',
    templateUrl: './contract-tile.component.html',
    styleUrls: ['./contract-tile.component.css']
})
export class ContractTileComponent implements OnInit {

    protected readonly String = String;

    @Input() public title = '';
    @Input() public icon = '';
    @Input() public style = {};
    @Input() public routerLink = '';
    @Input() public customClass = '';

    @Input() public contract_data: any | undefined;
    @Input() public user_id = '';

    @Output() public changed: EventEmitter<any> = new EventEmitter();

    private scUserStore: Store;
    private availableCommodityStore: Store;
    public is_loading = true;

    public clientName = '';
    public contractorName = '';
    public commodities: undefined | any[];
    public rewards: undefined | any[];
    public commodities_total = 0;

    constructor(
        private http: HttpClient,
        private router: Router,
        private storeService: StoreService,
        private authService: AuthService
    ) {
        this.scUserStore = this.storeService.getStore('sc_user');
        this.availableCommodityStore = this.storeService.getStore('sc_available_commodities');
    }

    ngOnInit() {
        const selectFilter = [];
        selectFilter.push('first=0');
        selectFilter.push('no_limit=1');

        const stores = [];

        if (!this.scUserStore.isLoaded()) {
            stores.push(this.scUserStore.loadStore('?' + selectFilter.join('&')));
        }
        if (!this.availableCommodityStore.isLoaded()) {
            stores.push(this.availableCommodityStore.loadStore('?' + selectFilter.join('&')));
        }

        const commodityStore = this.storeService.createNoneGlobalStore('sc_contract_commodities',
            'sc-contracts/' + this.contract_data.c_id + '/commodities', 'cc_id');
        stores.push(commodityStore.loadStore('?' + selectFilter.join('&')));

        const rewardStore = this.storeService.createNoneGlobalStore('sc_contract_rewards',
            'sc-contracts/' + this.contract_data.c_id + '/rewards', 'cr_id');
        stores.push(rewardStore.loadStore('?' + selectFilter.join('&')));

        Promise.all(stores).then((result) => {
            this.readUserNames();
            this.is_loading = false;
            this.setCommodityList(commodityStore);
            this.setRewardList(rewardStore);
        });
    }

    private setCommodityList(commodityStore: Store) {
        const records = commodityStore.getRecords();
        const commodities = [];
        this.commodities_total = 0;
        if (records && records.length > 0) {
            let pos = 0;
            records.forEach((record: any) => {
                pos++;
                const com = this.availableCommodityStore.getRecordById(record.data.commodity);
                commodities.push({
                    pos: pos,
                    commodity: record.data.commodity,
                    commodity_name: com.data.c_name,
                    min_required_value: record.data.min_required_value,
                    max_accepted_value: record.data.max_accepted_value,
                    sc_wiki_url: com.data.sc_wiki_url,
                    c_value: record.data.c_value,
                    item_sum: record.data.c_value * record.data.min_required_value
                });
                this.commodities_total += record.data.c_value * record.data.min_required_value;
            });
            this.commodities = commodities;
        }
    }

    private setRewardList(rewardStore: Store) {
        const records = rewardStore.getRecords();
        const rewards = [];
        if (records && records.length > 0) {
            let pos = 0;
            records.forEach((record: any) => {
                pos++;
                const com = this.availableCommodityStore.getRecordById(record.data.commodity);
                rewards.push({
                    pos: pos,
                    commodity: record.data.commodity,
                    commodity_name: com.data.c_name,
                    reward_value: record.data.reward_value,
                    c_value: record.data.c_value,
                    c_type: record.data.c_type,
                    sc_wiki_url: com.data.sc_wiki_url,
                    item_sum: record.data.c_value * record.data.reward_value
                });
            });
            this.rewards = rewards;
        }
    }

    private readUserNames() {
        const client = this.scUserStore.getRecordById(String(this.contract_data.client));
        const contractor = this.scUserStore.getRecordById(String(this.contract_data.contractor));

        if (client) {
            this.clientName = client.data.sc_username;
        }
        if (contractor) {
            this.contractorName = contractor.data.sc_username;
        }
    }

    public editContract() {
        this.router.navigateByUrl('/sc-contracts/' + this.contract_data.c_id);
    }

    public setStatus(status: string) {
        let contractor = '';
        const user_id = this.authService.getUserId();
        switch (status) {
            case 'taken':
                contractor = user_id;
                break;
            case 'published':
                break;
            case 'turned-in':
                contractor = user_id;
                break;
            case 'confirmed':
                contractor = user_id;
                break;
        }
        return this.http.patch(FbUtils.formatUrlForApi(AppSettings.getRealAPILink() + 'sc-contracts/' + this.contract_data.c_id),
            {
                status: status,
                contractor: contractor
            }).pipe(
            map((user: any) => {
            })
        ).subscribe((result) => {
            if (this.changed) {
                this.changed.emit(result);
            }
        });
    }

    deadlineReached() {
        return new Date(this.contract_data.deadline_ts).getTime() < new Date().getTime();
    }
}
